import React from 'react';
import PropTypes from 'prop-types';
import { Overview, Edit } from '../views';
import * as views from '../../views';
import Controllers from '../controllers';
import { StyledInnerContainer } from './style';

const viewsMap = {
    [views.OVERVIEW]: Overview,
    [views.EDIT]: Edit,
};

const Inner = ({ view, buttonsConfig, configs }) => {
    const View = viewsMap[view];
    return (
        <StyledInnerContainer>
            <View config={configs[view]} />
            <Controllers configs={buttonsConfig} />
        </StyledInnerContainer>
    );
};

Inner.propTypes = {
    view: PropTypes.oneOf(Object.keys(viewsMap)),
    configs: PropTypes.object,
    buttonsConfig: PropTypes.arrayOf(PropTypes.object),
};

export default Inner;
