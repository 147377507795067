import styled from '@emotion/styled';
import theme from '@24hr/resursbank-theme/ya-bank';

export const StyledDescriptionBoxContainer = styled.div`
    background-color: ${theme.colors.superLightGrey};
    padding: ${theme.spacing.indent};
    white-space: pre-wrap;
    @media all and (-ms-high-contrast: none) {
        display: block;
        min-height: 300px;
        height: 100px;
    }
    h1 {
        line-height: 5rem;
    }
`;
